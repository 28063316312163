.CsatSelector__stars {
	direction: rtl;
}
.CsatSelector__options {
	font-size: 50px;
	display: flex;
	justify-content: space-between;
	margin: auto;
	margin-top: 25px;
	margin-bottom: 85px;
	overflow: hidden;
}

a:not([href]) {
	color: red;
}

.Survey__options span {
	cursor: pointer;
	user-select: none;
	color: rgb(170, 170, 170);
	text-decoration: none;
	-webkit-transition: color 0.4s;
	-moz-transition: color 0.4s;
	-o-transition: color 0.4s;
	transition: color 0.4s;
}

.CsatSelector__option-button {
	margin: auto;
	padding: 10px;
	border-radius: 20px;
	height: 60px;
	margin-bottom: 4px;
}

.CsatSelector__check-button {
	border: 3px solid #2ba500 !important;
	background: #fff url(../../images/Chulo.png) 20px 20px no-repeat;
	background-size: 15%;
	background-position: 50%;
}

.CsatSelector__check-button:hover {
	background-color: #2ca5005d;
}

.CsatSelector__check-button:focus {
	background-color: #2ca5005d;
	box-shadow: 0px 0px 4px rgba(197, 197, 197, 0.575);
}

.CsatSelector__x-button {
	border: 3px solid #c70000 !important;
	background: #fff url(../../images/X.png) 20px 20px no-repeat;
	background-size: 15%;
	background-position: 50%;
}

.CsatSelector__x-button:hover {
	background-color: #c700005d;
}

.CsatSelector__x-button:focus {
	background-color: #c700005d;
	box-shadow: 0px 0px 10px rgba(197, 197, 197, 0.575);
}

button:focus {
	outline: none !important;
}
