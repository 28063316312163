* {
	text-align: center;
}

body {
	background-color: #ebebeb !important;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 26px !important;
}

.container {
	background-color: white;
	margin-top: 64px;
	max-width: 960px !important;
	margin-bottom: 64px;
	padding: 0px !important;
	border-radius: 12px;
	box-shadow: 0px 0px 10px rgb(197, 197, 197);
}

.Survey__options {
	font-size: 50px;
	display: flex;
	justify-content: space-between;
	margin: auto;
	margin-top: 25px;
	/* margin-bottom: 85px; */
	overflow: hidden;
}

.Survey__indicators {
	display: inline-block;
	width: 50%;
	margin-bottom: 85px;
	padding-top: -5px;
	font-size: 10px;
	color: #575757;
}

.Survey__header {
	background-color: #ffd300;
	border-radius: 12px 12px 0px 0px;
	box-shadow: 0px 0px 4px rgb(197, 197, 197);
	text-align: center;
	width: 100%;
	min-height: 100px;
}

.Survey__header img {
	max-width: 100%;
	padding: 22px;
	width: auto;
	min-height: 90px;
	max-height: 120px;
}

.Survey__greeting {
	margin: auto;
	margin-top: 83px;
	color: #383737;
	text-align: center;
}

.Survey__greeting hr {
	display: inline-block;
	background-color: rgba(197, 197, 197, 0.575);
	width: 24%;
	height: 0.5px;
	margin: 0px 10px;
	vertical-align: middle;
}

.Survey__greeting h5 {
	font-family: "Open Sans", sans-serif;
	font-size: 30px;
}

.Survey__greeting p {
	font-size: 22px;
	color: #797979;
	padding: 0px 40px;
	font-weight: 400;
}

.Survey__question {
	margin: auto;
	font-size: 20px;
	font-weight: 500;
	color: #3a3939;
}

.Survey__questions {
	margin: auto;
	margin-top: 70px;
}

.Survey__open-comment {
	width: 100%;
	font-size: 16px;
	text-align: left;
}

.Survey__open-comment:focus {
	width: 100%;
	border-color: #ffd300;
	box-shadow: 0px 0px 5px rgb(112, 112, 112) !important;
	font-size: 16px;
	text-align: left;
}

.Survey__send-button {
	background-color: #ffd300;
	border-radius: 8px;
	margin-top: 12px;
	margin-bottom: 83px;
	font-size: 25px;
	text-decoration: bold;
	height: 60px;
	border: 0;
}

.Survey__send-button:hover {
	box-shadow: 0px 0px 10px rgb(112, 112, 112);
}

.Survey__send-button[disabled]{
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
}

.Survey__empty-icon {
	background-color: #610101;
	font-weight: 700;
	color: #fff;
	border-radius: 4px;
	width: 20px;
	padding: 0px 4px;
}

.Survey__empty-msg {
	color: #610101;
	border-radius: 4px;
	font-size: 14px;
	text-align: left;
	margin-bottom: -1px;
}

@media only screen and (max-width: 420px) {
	.Survey__greeting h5 {
		font-size: 25px;
	}

	.Survey__question {
		font-size: 16px;
	}

	.Survey__greeting p {
		font-size: 17px;
		padding: 0px 20px;
	}

	.Survey__send-button {
		font-size: 20px;
		height: 50px;
	}
}
