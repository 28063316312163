.NpsSelector {
	font-size: 50px;
	display: flex;
	justify-content: space-between;
	margin: auto;
	margin-top: 25px;
	margin-bottom: 85px;
	overflow: hidden;
}

.NpsSelector__options {
	display: inline-block;
	color: white;
	border: 0px;
	width: 100%;
	text-align: center;
	user-select: none;
}

.NpsSelector__option {
	padding: 1rem 0px;
	font-size: 25px;
	display: inline-block;
	border: 0.5px solid white;
	width: 9%;
	-webkit-user-select: initial;
}

.NpsSelector__option:hover {
	cursor: pointer;
	user-select: none;
	border: 2px solid gray;
}

.NpsSelector__option:focus {
	border: 4px solid gray;
}

.NpsSelector__low-calif {
	background-color: #f3565b;
}

.NpsSelector__mid-calif {
	background-color: #ffb32e;
}

.NpsSelector__high-calif {
	background-color: #2ba500;
}

@media only screen and (max-width: 420px) {
	.NpsSelector {
		font-size: 10px;
		width: 100%;
	}

	.NpsSelector__question {
		font-size: 16px;
	}

	.NpsSelector__options {
		font-size: 40px;
	}

	.NpsSelector__option {
		font-size: 14px;
		margin-left: -2px;
	}
}
